<template lang="pug">
.FTestimonialCard(:style="style")
  .FTestimonialCard__category(v-if="category") {{ category }}
  h4(v-html="testimonial")
  .FTestimonialCard__reviewer
    FAvatar(
      :src="avatarImg"
      :placeholder="avatarPlaceholder"
    )
    .FTestimonialCard__reviewerInfos
      h6.FTestimonialCard__reviewerInfos__name {{ testimonialUserName }}
      span.FTestimonialCard__reviewerInfos__title {{ testimonialUserDescription }}
</template>

<style lang="stylus">
.FTestimonialCard
  display flex
  flex-direction column
  padding rem(80)
  height 100%
  background var(--FTestimonialCard--backgroundColor)
  color var(--FTestimonialCard--color)
  border-radius rem(16)
  max-width rem(1088)
  justify-content space-between

  +media-down('sm')
    max-width none

  &__category
    text-transform uppercase
    font-weight 700
    padding-bottom rem(16)
    color var(--FTestimonialCard--categoryColor)

  +media-down('sm')
    padding rem(40)

    h4
      font-size rem(24)
      line-height rem(32)

  +media-down('xs')
    padding rem(24)

.FTestimonialCard__reviewer
  display flex
  margin-top rem(32)

  .FAvatar
    flex-shrink 0

  .FTestimonialCard__reviewerInfos
    display flex
    flex-direction column
    justify-content center
    margin-left rem(24)

    &__name
      font-weight 700

    +media-down('xs')
      margin-left rem(12)

      &__name
        font-size 1rem

      &__title
        font-size 0.8rem
</style>

<script setup lang="ts">
export interface FTestimonialCardProps {
  /**
   * Background color of the card
   */
  background?: string;
  /**
   * Text color shade (regarding the background color for category)
   */
  textColorShade?: 'lighter' | 'darker';
  /**
   * Name of the user category
   */
  category?: string;
  /**
   * Text of the testimonial
   */
  testimonial?: string;
  /**
   * Image path of the avatar
   */
  avatarImg: string;
  /**
   * Image placeholder path of the avatar
   */
  avatarPlaceholder?: string;
  /**
   * Fullname of the user
   */
  testimonialUserName?: string;
  /**
   * Description of the user
   */
  testimonialUserDescription?: string;
}

const props = withDefaults(defineProps<FTestimonialCardProps>(), {
  background: 'primary',
  textColorShade: 'lighter',
  category: '',
  testimonial: '',
  testimonialUserName: '',
  testimonialUserDescription: '',
  avatarPlaceholder: '',
});

const computedTextColorShade = computed(() =>
  props.textColorShade === 'lighter' ? 'neutral--light-5' : 'secondary'
);

const computedCategoryColorShade = computed(() =>
  props.textColorShade === 'lighter' ? 'light-2' : 'dark-2'
);

const style = computed(
  (): Style => ({
    '--FTestimonialCard--color': getCssColor(computedTextColorShade.value),
    '--FTestimonialCard--backgroundColor': getCssColor(props.background),
    // Category color shade regarding the background color
    '--FTestimonialCard--categoryColor': getCssColor(
      `${props.background}--${computedCategoryColorShade.value}`
    ),
  })
);
</script>
